import {api, routes} from "@/plugins/axios";

export function useMembers() {
    const getMember = (organizationSlug, memberUid) => {
        return api
            .get(routes.members.get(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const getMemberByUserUid = (organizationSlug, userUid) => {
        return api
            .get(routes.members.showByUser(organizationSlug, userUid))
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const updateMember = (organizationSlug, memberUid, memberData) => {
        const data = {
            first_name: memberData.firstName,
            last_name: memberData.lastName,
            middle_name: memberData.middleName,
            work_status: memberData.workStatus,
            status: memberData.status,
        }

        if (memberData.role) {
            data.roles = [memberData.role];
        }

        return api
            .put(routes.members.manage.update(organizationSlug, memberUid), data)
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const uploadAvatar = (organizationSlug, memberUid, memberPhoto) => {
        const formData = new FormData();
        formData.append("avatar", memberPhoto);

        return api
            .post(routes.members.avatar.store(organizationSlug, memberUid), formData)
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const deleteAvatar = (organizationSlug, memberUid) => {
        return api
            .delete(routes.members.avatar.delete(organizationSlug, memberUid))
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const sendPhoneCode = (organizationSlug, memberUid, phoneNumber) => {
        return api
            .post(routes.members.phoneNumbers.sendCode(organizationSlug, memberUid), {
                phone_number: phoneNumber,
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const checkPhoneCode = (organizationSlug, memberUid, code, phoneNumber) => {
        return api
            .post(
                routes.members.phoneNumbers.checkCode(organizationSlug, memberUid),
                {
                    code: code,
                    phone_number: phoneNumber,
                }
            )
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const sendEmailCode = (organizationSlug, memberUid, email) => {
        return api
            .post(routes.members.emails.sendCode(organizationSlug, memberUid), {
                email: email,
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const checkEmailCode = (organizationSlug, memberUid, code, email) => {
        return api
            .post(routes.members.emails.checkCode(organizationSlug, memberUid), {
                code: code,
                email: email,
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const createPhoneNumber = (organizationSlug, memberUid, phoneNumber) => {
        return api
            .post(routes.members.phoneNumbers.create(organizationSlug, memberUid), {
                phone_number: phoneNumber,
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const createEmail = (organizationSlug, memberUid, email) => {
        return api
            .post(routes.members.emails.create(organizationSlug, memberUid), {
                email: email,
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const getPrimaryPhoneNumber = (phone_numbers) => {
        return phone_numbers.find((phone) => phone.is_primary) || null;
    };

    const getPrimaryEmailAddress = (emails) => {
        return emails.find((email) => email.is_primary) || null;
    };

    const getMembers = (
        organizationSlug,
        page = 1,
        perPage = 10,
        search = "",
        groups = [],
        accountStatuses = [],
        orderBy = "name",
        orderByDirection = "asc",
        fields = [],
        filtersOperator = "and",
        workStatuses = [],
    ) => {
        return api
            .get(
                routes.members.list(
                    organizationSlug,
                    page,
                    perPage,
                    search,
                    groups,
                    accountStatuses,
                    orderBy,
                    orderByDirection,
                    fields,
                    filtersOperator,
                    workStatuses,
                )
            )
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const getLeadership = (organizationSlug, page = 1, perPage = 10) => {
        return api
            .get(routes.members.leadership(organizationSlug, page, perPage))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const downloadMembersList = (
        organizationSlug,
        search = "",
        groups = [],
        statuses = [],
        orderBy = "name",
        direction = "asc",
        fields = [],
        workStatuses = [],
        filtersOperator = "and",
    ) => {
        return api
            .get(
                routes.members.download(
                    organizationSlug,
                    search,
                    groups,
                    statuses,
                    orderBy,
                    direction,
                    fields,
                    workStatuses,
                    filtersOperator,
                ),
                {responseType: "blob"}
            )
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const currentDate = new Date().toLocaleDateString("en-US");
                const filename = `members_export_${currentDate}.xlsx`;
                link.setAttribute("download", filename); // Adjust the filename as needed
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                return Promise.resolve();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const createLeader = (organizationSlug, memberUid, workTitle) => {
        return api
            .post(routes.members.createLeader(organizationSlug, memberUid), {
                work_title: workTitle,
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const getAvailableStatuses = (organizationSlug) => {
        return api
            .get(routes.members.getAvailableStatuses(organizationSlug))
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const getAvailableWorkStatuses = (organizationSlug) => {
        return api
            .get(routes.members.getAvailableWorkStatuses(organizationSlug))
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const activateMember = (organizationSlug, memberUid) => {
        return api
            .post(routes.members.manage.activate(organizationSlug), {
                uid: memberUid,
            })
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const deactivateMember = (organizationSlug, memberUid) => {
        return api
            .delete(routes.members.manage.deactivate(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const getRoles = (organizationSlug) => {
        return api
            .get(routes.organization.getRoles(organizationSlug))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const getProfileStatus = (organizationSlug, memberUid) => {
        return api
            .get(routes.members.getProfileStatus(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response.data.status);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const getFieldsStatus = (organizationSlug, memberUid) => {
        return api
            .get(routes.members.getFieldsStatus(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response.data.status);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const getOnboardingStatus = (organizationSlug, memberUid) => {
        return api
            .get(routes.members.getOnboardingStatus(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response.data.status);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const finishOnboarding = async (organizationSlug, memberUid) => {
        return api
            .patch(routes.members.finishOnboarding(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const resendInvitation = async (organizationSlug, memberUid) => {
        return api
            .post(routes.members.manage.resendInvitation(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const bulkDelete = async (organizationSlug, payload) => {
        return api
            .post(routes.members.bulk.delete(organizationSlug), payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    const getMemberAuthenticationNumber = (organizationSlug, memberUid) => {
        return api
            .get(routes.members.getAuthenticationNumber(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    const bulkActivate = async (organizationSlug, payload) => {
        return api
            .post(routes.members.bulk.activate(organizationSlug), payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    const bulkUpdate = async (organizationSlug, payload) => {
        return api
            .post(routes.members.bulk.update(organizationSlug), payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    const bulkDeactivate = async (organizationSlug, payload) => {
        return api
            .post(routes.members.bulk.deactivate(organizationSlug), payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    const bulkResendInvitation = async (organizationSlug, payload) => {
        return api
            .post(routes.members.bulk.resendInvitation(organizationSlug), payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    const bulkInviteToGroup = async (organizationSlug, payload) => {
        return api
            .post(routes.members.bulk['add-to-groups'](organizationSlug), payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    return {
        getMember,
        getMemberByUserUid,
        updateMember,
        getMembers,
        sendEmailCode,
        checkEmailCode,
        sendPhoneCode,
        checkPhoneCode,
        createPhoneNumber,
        createEmail,
        getPrimaryEmailAddress,
        getPrimaryPhoneNumber,
        uploadAvatar,
        deleteAvatar,
        getLeadership,
        downloadMembersList,
        createLeader,
        getAvailableStatuses,
        activateMember,
        deactivateMember,
        getAvailableWorkStatuses,
        getRoles,
        getProfileStatus,
        getFieldsStatus,
        getOnboardingStatus,
        finishOnboarding,
        resendInvitation,
        bulkDelete,
        getMemberAuthenticationNumber,
        bulkActivate,
        bulkUpdate,
        bulkDeactivate,
        bulkResendInvitation,
        bulkInviteToGroup
    };
}
