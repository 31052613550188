import {useAuth} from "@/stores/AuthStore";
import {useSignUp} from "@/stores/SignUpStore";
import {useRedirect} from "@/stores/RedirectStore";

const auth = function({ to, next }) {
    const auth = useAuth();
    const signUp = useSignUp();

    if (signUp.inProgress) {
        return next({
            name: 'register'
        });
    }

    if (!auth.isAuthenticated) {
        const redirectStore = useRedirect();

        if (to.name && to.name !== 'login' && to.name !== 'register') {
            redirectStore.setRedirectUrl(to.fullPath);
        }

        return next({
            name: 'login'
        });
    }

    return next();
}

const guest = function({ next }) {
    const auth = useAuth();
    const signUp = useSignUp();

    if (auth.isAuthenticated && !signUp.inProgress && auth.member) {
        return next({
            name: 'dashboard'
        });
    }

    return next();
}

const requestAccessMiddleware = function({ next }) {
    const auth = useAuth();
    const signUp = useSignUp();

    if (!auth.isAuthenticated || auth.isAuthenticated && signUp.inProgress) {
        return next({
            name: 'login'
        });
    }

    if (auth.isAuthenticated && !signUp.inProgress && auth.member) {
        return next({
            name: 'dashboard'
        });
    }

    if(!auth.hasOrganization) {
        return next({
            name: 'launchpad'
        });
    }

    return next();
}

export {auth, guest, requestAccessMiddleware}
