module.exports = {
    content: [
        "./index.html",
        "./src/**/*.{vue,js,ts,jsx,tsx}",
        "@/src/assets/**/*.{html,js,vue}",
    ],
    theme: {
        screens: {
            xxs: "320px",
            xs: "360px",
            sm: "480px",
            md: "768px",
            lg: "976px",
            mlg: "1280px",
            xl: "1440px",
            xxl: "1920px",
            xxxl: "2560px",
        },
        fontFamily: {
            sans: ["Poppins", "Helvetica", "Arial", "sans-serif"],
        },
        extend: {
            aspectRatio: {
                'profile': '1 / 1',
                'cover': '100 / 21',
                'thumbnail': '30 / 16',
            },
            width: {
                132: "34rem",
                4.5: "1.125rem",
            },
            colors: {
                "light-gray": "#999999",
                default: {
                    1: "#495057",
                    2: "#74788D",
                    3: "#A6B0CF",
                    4: "#BFC1CA",
                    5: "#34C38F",
                    6: "#F1B44C",
                    7: "#F46A6A",
                    9: "#364540",
                    10: "#F2F2F2",
                },
                interactive: {
                    1: "#34C38F",
                    2: "#F1B44C",
                    3: "#F46A6A",
                    5: "#343A40",
                    6: "#74788D",
                    7: "#A6B0CF",
                    8: "#CED4DA",
                    9: "#EFF2F7",
                    10: "#BFC1CA",
                    11: "#e5e6ec",
                },
                primary: {
                    1: "var(--primary-color)",
                    2: "#DA5959",
                    3: "#50A5F1",
                },
                background: {
                    1: "#2A3042",
                    3: "#F8F8FB",
                    4: "#3D4252",
                    5: "#FFBBBB",
                },
                semitone: {
                    color: {
                        1: "#DAF4EB",
                        2: "#FCF1DF",
                        3: "#FDE4E4",
                        4: "#DADBDD",
                        5: "#C8F0E2",
                    },
                    primary: {
                        1: "#E0E5FA",
                        2: "#DFEFFC",
                    },
                    "modal-overlay": " rgba(0, 0, 0, 0.6)",
                },
                tag: {
                    primary: {
                        1: "#65658C",
                        2: "#FF7500",
                        3: "#FF0000",
                        4: "#9C037C",
                        5: "#3C0594",
                        6: "#2546B0",
                        7: "#004A66",
                        8: "#026060",
                        9: "#087E44",
                        10: "#226501",
                    },
                    secondary: {
                        1: "#F7F7FC",
                        2: "#FFF1E5",
                        3: "#FFECEC",
                        4: "#FFF5FD",
                        5: "#F7F2FF",
                        6: "#F2F5FF",
                        7: "#F1FBFF",
                        8: "#EAFFFF",
                        9: "#E5FFF2",
                        10: "#F0FFE9",
                    },
                },
                notification: {
                    primary: {
                        1: "#FF9800",
                        2: "#CD3FE4",
                        3: "#8841FB",
                        4: "#7950F2",
                        5: "var(--primary-color)",
                        6: "#50A5F1",
                        7: "#20CED9",
                        8: "#1EB739",
                        9: '#3399ff',
                    },
                    secondary: {
                        1: "#FFEED5",
                        2: "#FADEFF",
                        3: "#E7D9FE",
                        4: "#E8E1FF",
                        5: "#E4E8FF",
                        6: "#E4F2FF",
                        7: "#DBFCFE",
                        8: "#E6FFEA",
                    },
                    icon: {
                        green: "#D9F2DD",
                        purple: "#E4DCFC",
                        orange: "#FFEACC",
                    },
                },
                dark: {
                    1: '#030821',
                    2: '#8A8FAA',
                    primary: {
                        1: "#7083DB",
                        2: "#556DE6",
                    },
                    text: {
                        1: "#FFFFFF",
                        3: "#8A8FAA",
                    },
                    default: {
                        10: "#E8E9EE",
                    },
                    secondary: {
                        1: "#4B5279",
                        2: "#29325D",
                    },
                    background: {
                        1: "#080C1F",
                        2: "#171C36",
                        3: "#1B213E",
                    },
                    input: {
                        background: '#d9dcef',
                        border: '#d9dcef',
                        borderHover: '#dadbdf',
                    }
                },
            },
            placeholderColor: {
                primary: "#BFC1CA",
            },
            fontSize: {
                "display-1": ["96px", "144px"],
                "display-2": ["88px", "132px"],
                "display-3": ["72px", "108px"],
                "display-4": ["56px", "84px"],
                "display-5": ["40px", "60px"],
                "body-1": ["32px", "48px"],
                "body-2": ["24px", "36px"],
                "body-3": ["20px", "28px"],
                "body-4": ["18px", "24px"],
                "body-5": ["16px", "22px"],
                "body-6": ["14px", "20px"],
                "body-7": ["12px", "16px"],
                "button-5": ["14px", "20px"],
                "plain-5": ["16px", "26px"],
                "uppercase-1": ["16px", "24px"],
                "uppercase-2": ["12px", "18px"],
                tag: ["10px", "12px"],
            },
            margin: {
                "r-full": "100%",
            },
            keyframes: {
                pulse: {
                    "0%, 100%": {opacity: 1},
                    "50%": {opacity: 0.5},
                },
            },
            animation: {
                pulse: "pulse 1s ease-in-out infinite",
            },
            containers: {
                'xxs': '10rem',
            },
        },
    },
    plugins: [
        require("@tailwindcss/forms"),
        require('@tailwindcss/container-queries'),
        require('@tailwindcss/typography'),
    ],
    variants: {
        extend: {
            display: ["group-hover"],
        },
    },
};
