<template>
  <div class="h-auto loaded">
    <SidebarWrapper :sidebarOpen="sidebarOpen" @close="closeSidebar"/>
    <div class="md:pl-64 flex flex-col flex-1 h-auto">
      <NavbarWrapper @toggleSidebar="toggleSidebar"/>
      <div ref="viewContainer" class="xxs:m-0 xxs:mt-6 h-auto bg-background-3 md:m-6">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeUnmount, onMounted, provide, reactive, ref, watch} from 'vue'
import SidebarWrapper from '../../components/wrappers/SidebarWrapper.vue';
import NavbarWrapper from '../../components/wrappers/NavbarWrapper.vue';
import { useRoute } from 'vue-router'
import {useIsMobile} from "@/composables/useIsMobile";

const {isMobile, clearIsMobileListener, initIsMobileListener} = useIsMobile();
const router = useRoute();

const sidebarOpen = ref(false);
const viewContainer = ref(null);
const dimensions = reactive({width: 500, height: 450});
function closeSidebar() {
    sidebarOpen.value = false;
}

function toggleSidebar() {
    sidebarOpen.value = !sidebarOpen.value;
}

function updateDimensions() {
    if (viewContainer.value) {
        const rect = viewContainer.value.getBoundingClientRect();
        dimensions.width = rect.width;
        dimensions.height = rect.height;
    }
}

let unwatch = null

onMounted(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    initIsMobileListener();

    if(isMobile.value) {
        unwatch = watch(() => router.fullPath, (newPath, oldPath) => {
            if(newPath !== oldPath && isMobile) {
                closeSidebar();
            }
        })
    }
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateDimensions);

    clearIsMobileListener();

    if(unwatch) {
        unwatch();
    }
})

provide('dimensions', dimensions);
</script>
