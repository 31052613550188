import {defineStore} from "pinia";
import {ref} from "vue";

export const useRedirect = defineStore('useRedirect', () => {
    const redirectUrl = ref(null)

    function setRedirectUrl(url) {
        redirectUrl.value = url
    }

    function getAndClearRedirectUrl() {
        const url = redirectUrl.value
        redirectUrl.value = null
        return url
    }

    return {
        redirectUrl,
        setRedirectUrl,
        getAndClearRedirectUrl
    }
});