import {createApp} from 'vue';
import {createPinia} from 'pinia';
import Maska from 'maska';
import {messaging} from './plugins/firebase.js';
import AppLayout from '@/views/layouts/AppLayout.vue';
import cookieStoragePlugin from "./pinia/cookieStorage";
import App from '@/App.vue';
import router from '@/router';
import '@/assets/style/site.scss';
import Vue3Toastify from "vue3-toastify";
import 'vue3-toastify/dist/index.css';
import {useSubdomain} from "@/composables/useSubdomain";
import {useAuth} from "@/stores/AuthStore";
import {CometChat} from '@cometchat-pro/chat'
import * as Sentry from "@sentry/vue";
import '@vuepic/vue-datepicker/dist/main.css'


function loadData() {
    const {getSubdomain} = useSubdomain();
    const {init, isAuthenticated} = useAuth();

    const subdomain = getSubdomain();

    if (!subdomain) {
        if (isAuthenticated) {
            return router.push('launchpad');
        }
        return;
    }

    return init(subdomain);
}

var appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(window.appConfig.COMETCHAT_REGION)
    .build();

(async () => {
    const pinia = createPinia()
        .use(cookieStoragePlugin);

    const app = createApp(App)
        .use(pinia)
        .use(Maska)
        .use(Vue3Toastify,
            {
                autoClose: 3000,
                icon: false,
                dangerouslyHTMLString: true,
            })
        .component('AppLayout', AppLayout)

    const environmentSampleRates = {
        'staging': 0.01,
        'pre-production': 0.01,
        'production': 0.01,
    };

    Sentry.init({
        app,
        release: window.appConfig.SENTRY_RELEASE,
        environment: window.appConfig.APP_ENV,
        dsn: window.appConfig.SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [window.appConfig.GATEWAY_API_URL],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        tracePropagationTargets: [window.appConfig.GATEWAY_API_URL],
        // Performance Monitoring
        tracesSampleRate: environmentSampleRates[window.appConfig.APP_ENV] ?? 0.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: environmentSampleRates[window.appConfig.APP_ENV] ?? 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: environmentSampleRates[window.appConfig.APP_ENV] ?? 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    app.provide('messaging', messaging);

    await loadData();

    await CometChat.init(window.appConfig.COMETCHAT_APP_ID, appSetting);

    app.use(router);
    app.mount('#app');
})();


