<template>
    <p class="text-body-3 text-white font-medium">Basic Info</p>

    <div class="my-6">
        <div class="mb-6">
            <core-input
                colorMode="dark"
                name="first_name"
                label="First Name"
                label-position="above"
                placeholder="First Name"
                v-model="firstName.value.value"
                :has-error="errors.firstName !== null"
                :hint-text="errors.firstName"
                :required="true"
            >
            </core-input>
        </div>

        <div class="mb-6">
            <core-input
                colorMode="dark"
                name="last_name"
                label="Last Name"
                label-position="above"
                placeholder="Last Name"
                v-model="lastName.value.value"
                :has-error="errors.lastName !== null"
                :hint-text="errors.lastName"
                :required="true"
            >
            </core-input>
        </div>

        <div class="mb-6">
            <core-input
                colorMode="dark"
                v-maska="phoneMask"
                name="phone_number"
                label="Mobile Number"
                label-position="above"
                :placeholder="phonePlaceHolder"
                v-model="phoneNumber.value.value"
                :has-error="errors.phoneNumber !== null"
                :hint-text="errors.phoneNumber"
                @focusin="signUpStore.resetErrors()"
                @keyup.enter="handleOnVerify"
                :required="true"
            >
            </core-input>
        </div>
        <div class="mt-4 flex flex-row">
            <CoreCheckbox v-model="terms.value.value" color="#556EE6" class="mr-2" />
            <p class="text-body-6 font-normal text-default-3">
                I agree to the following <a class="text-primary-1" href="https://www.nepservices.com/legal/terms-of-service" target="_blank">Terms and Conditions</a>.
                <span class="text-default-7"> * </span>
            </p>
        </div>
        <div class="mt-4 flex flex-row items-start">
            <CoreCheckbox v-model="smsTerms.value.value" color="#556EE6" class="mr-2" />
            <p class="text-body-6 font-normal text-default-3 -mt-1">
                I agree to receive member-specific SMS messages for union notifications and updates. Standard message and data rates may apply. <span class="underline">Reply STOP to unsubscribe.</span><span class="text-default-7"> * </span>
            </p>
        </div>
    </div>

    <div class="mt-10">
        <CoreButton
            :disabled="!meta.valid || signUpStore.loading"
            button-type="primary"
            @click="handleOnVerify"
            label="Send verification code"
            :loading="buttonLoader"
        />
        <router-link to="/login" class="w-full text-blue-300 text-body-6 mt-6 flex items-center hover:text-white cursor-pointer">
            <img src="@/assets/icons/chevron-left.svg" alt="arrow-left" class="inline-block mr-2 w-3 h-3" />
            I already have an account
        </router-link>
    </div>
    <Modal v-model:show="showPhoneVerificationModal"
           @update:show="showPhoneVerificationModal = !showPhoneVerificationModal"
           @close="handleCancelVerify"
           :colorMode="colorMode"
    >
            <VerifyPhoneNumber
                v-if="signUpStore.user.uid"
                :show="showPhoneVerificationModal"
                :uid="signUpStore.user.uid"
                :phone-number="phoneNumber.value.value"
                @canceled="handleCancelVerify"
                @finished="handleVerified"
                :colorMode="colorMode"
            />
    </Modal>
   <Modal v-model:show="showExistingUserModal"
          @update:show="showExistingUserModal = !showExistingUserModal"
            @close="showExistingUserModal = false">
       <ExistingUser :phone-number="phoneNumber.value.value" @close="showExistingUserModal = false"/>
   </Modal>

</template>
<script setup>
import CoreCheckbox from '@/components/core/Checkbox.vue';
import CoreButton from '@/components/core/Button.vue';
import CoreInput from "@/components/core/InputGroup";
import VerifyPhoneNumber from "@/components/modals/partials/VerifyPhoneNumber.vue";
import {useSignUp} from '@/stores/SignUpStore';
import {useCountdown} from '@/stores/CountdownStore';
import {useVerifyPhoneNumber} from "@/stores/VerifyPhoneNumberStore";
import {useAuth} from "@/stores/AuthStore";
import {useLocale} from "@/stores/LocaleStore";
import {useField, useForm} from 'vee-validate';
import {phone} from "@/yup/validations";
import {computed, ref} from "vue";
import * as yup from 'yup';
import {useRouter} from 'vue-router';
import {storeToRefs} from "pinia";
import {getCountryCodeForRegionCode} from "awesome-phonenumber";
import Modal from "@/components/core/Modal.vue";
import ExistingUser from "@/components/modals/partials/ExistingUser.vue";

defineProps({
    colorMode: {
        type: String,
        default: 'light'
    }
});

const signUpStore = useSignUp();
const countDownStore = useCountdown();
const verifyPhoneNumberStore = useVerifyPhoneNumber();
const {organization} = storeToRefs(useAuth());
const {phoneVerificationModal: showPhoneVerificationModal} = storeToRefs(signUpStore);

const router = useRouter();
const showExistingUserModal = ref(false);

const buttonLoader = ref(false)

yup.addMethod(yup.StringSchema, 'phone', phone);

const {phoneNumberRegion} = storeToRefs(useLocale());
const countryCode = getCountryCodeForRegionCode(phoneNumberRegion.value);

const phoneMask = '+' + countryCode + ' (###) ###-####';
const phonePlaceHolder = '+' + countryCode + ' (___) ___-____';

const {meta} = useForm({
    initialValues: {
        first_name: signUpStore.user.first_name,
        last_name: signUpStore.user.last_name,
        phone_number: signUpStore.user.phone_number,
        terms: false
    },
});

const firstName = useField('first_name', yup.string().required().matches(/^[A-Za-z-'" ]*$/, 'Please enter a valid name').label('The first name'));
const lastName = useField('last_name', yup.string().required().matches(/^[A-Za-z-'" ]*$/, 'Please enter a valid name').label('The last name'));
const phoneNumber = useField('phone_number', yup.string().phone().label('The phone number'));
const terms = useField('terms', yup.boolean().required().oneOf([true]));
const smsTerms = useField('smsTerms', yup.boolean());

const errors = computed(() => {
    return {
        firstName: signUpStore.errors.first_name ?? (firstName.errorMessage.value ?? null),
        lastName: signUpStore.errors.last_name ?? (lastName.errorMessage.value ?? null),
        phoneNumber: signUpStore.errors.phone_number ?? (phoneNumber.errorMessage.value ?? null)
    };
});

const handleOnVerify = async () => {
    signUpStore.resetErrors();

    if (!meta.value.valid) {
        return
    }

    buttonLoader.value = true;

    signUpStore.user.terms = true;

    if (signUpStore.user.phone_number !== phoneNumber.value.value) {
        signUpStore.clearUser();
        countDownStore.resetTimer();
    }

    if (!signUpStore.user.uid) {
        await signUpStore.createUser(firstName.value.value, lastName.value.value, phoneNumber.value.value);

        if(errors.value.phoneNumber === "The phone number is already used by someone else.") {
            showExistingUserModal.value = true;
        }

        buttonLoader.value = false;
        return;
    }

    if (countDownStore.countdownFinished) {
        verifyPhoneNumberStore.sendOTP(signUpStore.user.uid, signUpStore.user.phone_number).then(() => {
            countDownStore.startTimer(30);
        });
    }

    buttonLoader.value = false;
    signUpStore.showPhoneVerification();
}
const handleVerified = () => {
    showPhoneVerificationModal.value = false;

    if (organization.value === null) {
        verifyPhoneNumberStore.reset();
        signUpStore.nextStep();
    }
}

const handleCancelVerify = () => {
    if (verifyPhoneNumberStore.verified) {
        handleVerified();
    }
    signUpStore.hidePhoneVerification();
    verifyPhoneNumberStore.error = '';
}
</script>
