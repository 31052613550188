<template>
  <div class="pb-6">
    <div class="mx-auto flex items-center justify-center">
      <Done :color="color.hex"/>
    </div>

    <div class="flex flex-col mt-2 mb-6">
      <p :class="[styleClasses.title.general, styleClasses.title.color]">Congratulations!</p>
      <p :class="[styleClasses.text.general, styleClasses.text.color]">
        Your phone <span class="font-medium"> {{ phoneNumber }} </span> is verified.
      </p>
    </div>

    <div class="flex justify-center">
      <CoreButton @click="emit('finished')">Got it</CoreButton>
    </div>
  </div>
</template>
<script setup>
import CoreButton from '@/components/core/Button.vue';
import Done from "@/components/icons/phone/Done.vue";
import {useTheme} from "@/stores/ThemeStore";
import {computed} from "vue";

const {color} = useTheme();

const props = defineProps({
    phoneNumber: {
        required: true,
        type: String
    },
    colorMode: {
        required: true,
        type: String
    }
});

const emit = defineEmits([
    'finished',
]);

const styleClasses = computed(() => {
    return {
        title: {
            general: 'text-center text-body-3 font-medium',
            color: {
                'text-default-1': props.colorMode === 'light',
                'text-white': props.colorMode === 'dark',
            },
        },
        text: {
            general: 'text-center text-body-6 mt-4',
            color: {
                'text-default-1': props.colorMode === 'light',
                'text-default-3': props.colorMode === 'dark',
            },
        }
    }
})
</script>